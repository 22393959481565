import React, { useState } from "react";
import "../styles/upload.css";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  Alert,
  Modal,
  AlertHeading,
} from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faCheckCircle,
  faUpload,
  faCloudUpload,
  faPerson,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

function FileUpload() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [redactedPhones, setRedactedPhones] = useState([]);
  const [redactedEmails, setRedactedEmails] = useState([]);
  const [redactedNames, setRedactedNames] = useState([]);
  const [filePath, setFilePath] = useState("");
  const [step, setStep] = useState(1);
  const [redactionOptions, setRedactionOptions] = useState({
    phoneNumbers: false,
    emailAddresses: false,
    names: false,
    all: false,
  });
  const [pdfBlob, setPdfBlob] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setErrorMessage("");
  };

  const handleRedactionChange = (event) => {
    const { name, checked } = event.target;
    setRedactionOptions((prev) => ({ ...prev, [name]: checked }));
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("redactionOptions", JSON.stringify(redactionOptions));

      try {
        const response = await axios.post(
          "http://localhost:5000/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        setMessage(response.data.message);
        setRedactedPhones(response.data.redacted_text.phones);
        setRedactedEmails(response.data.redacted_text.emails);
        setRedactedNames(response.data.redacted_text.names);
        setFilePath(response.data.file_path);
        setStep(2);
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file");
      }
    } else {
      setErrorMessage("Please Upload a File Before Continuing");
    }
  };

  const nextStep = async () => {
    if (step === 1) {
      await handleSubmit();
    } else if (step < 3) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const stepProgress = () => {
    switch (step) {
      case 1:
        return 33;
      case 2:
        return 66;
      case 3:
        return 100;
      default:
        return 0;
    }
  };

  const redactDocument = async (event) => {
    const formData = new FormData();
    formData.append(
      "redactedText",
      JSON.stringify({ redactedEmails, redactedPhones, redactedNames })
    );
    formData.append("filePath", JSON.stringify({ filePath }));

    try {
      const response = await axios.post(
        "http://localhost:5000/download",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );
      console.log(response.data);
      setPdfBlob(new Blob([response.data], { type: "application/pdf" }));
    } catch (error) {
      console.error("Error redacting file:", error);
      alert("Failed to redact file");
    }
  };

  const handleDownload = () => {
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "redacted_document.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Container fluid className="upload-container">
      <Row className="justify-content-center">
        <Col md={10}>
          <h2 className="text-center">Document Redaction Process</h2>

          <ProgressBar
            now={stepProgress()}
            className="mb-4"
            variant="success"
            animated
          />

          {step === 1 && (
            <div>
              <h3 className="text-center">
                Upload File
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ marginLeft: "10px", color: "#28a745" }}
                />
              </h3>
              <p className="text-center">Please upload your file below:</p>
              <Form onSubmit={handleSubmit} className="upload-form">
                <Form.Group controlId="formFile" className="mb-3 pb-3">
                  <Form.Label>Select document</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
                {file && (
                  <div>
                    <h5>Selected File:</h5>
                    <p>{file.name}</p>
                    <p>File size: {Math.round(file.size / 1024)} KB</p>
                  </div>
                )}
                <h4 className="text-center">Select Redaction Options:</h4>
                <Form.Group>
                  <Row>
                    <Form.Check
                      type="checkbox"
                      label="Redact All"
                      name="all"
                      checked={redactionOptions.all}
                      onChange={handleRedactionChange}
                      className="redact-checkbox"
                    />
                  </Row>
                  <Row>
                    <Form.Check
                      type="checkbox"
                      label="Redact Phone Numbers"
                      name="phoneNumbers"
                      checked={
                        redactionOptions.phoneNumbers || redactionOptions.all
                      }
                      onChange={handleRedactionChange}
                      className="redact-checkbox"
                    />
                  </Row>
                  <Row>
                    <Form.Check
                      type="checkbox"
                      label="Redact Email Addresses"
                      name="emailAddresses"
                      checked={
                        redactionOptions.emailAddresses || redactionOptions.all
                      }
                      onChange={handleRedactionChange}
                      className="redact-checkbox"
                    />
                  </Row>
                  <Row>
                    <Form.Check
                      type="checkbox"
                      label="Redact Names"
                      name="names"
                      checked={redactionOptions.names || redactionOptions.all}
                      onChange={handleRedactionChange}
                      className="redact-checkbox"
                    />
                  </Row>
                </Form.Group>
                {errorMessage && (
                  <Alert key={0} variant={"danger"}>
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            </div>
          )}

          {/* Step 2: Review Redacted Information */}
          {step === 2 && (
            <div>
              <h3 className="text-center">
                {message}{" "}
                <FontAwesomeIcon
                  icon={faCloudUpload}
                  style={{ marginLeft: "10px", color: "#28a745" }}
                />
              </h3>
              <p className="text-center">Here is what we plan to redact:</p>
              <Row>
                {redactedPhones.length > 0 && (
                  <Col md={4}>
                    <Card className="card">
                      <Card.Body>
                        <Card.Title>
                          {" "}
                          <FontAwesomeIcon icon={faPhone} /> Phone Numbers
                        </Card.Title>
                        <div>
                          <ul>
                            {redactedPhones.map((phone, index) => (
                              <li key={index}>{phone}</li>
                            ))}
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {redactedEmails.length > 0 && (
                  <Col md={4}>
                    <Card className="card">
                      <Card.Body>
                        <Card.Title>
                          <FontAwesomeIcon icon={faEnvelope} /> Email Addresses
                        </Card.Title>
                        <div>
                          <ul>
                            {redactedEmails.map((email, index) => (
                              <li key={index}>{email}</li>
                            ))}
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                <Col md={4}>
                  {redactedNames !== "" && (
                    <Card className="card">
                      <Card.Body>
                        <Card.Title>
                          <FontAwesomeIcon icon={faPerson} /> Names
                        </Card.Title>
                        <div>
                          <ul>
                            {redactedNames.map((name, index) => (
                              <li key={index}>{name}</li>
                            ))}
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </div>
          )}

          {step === 3 && (
            <div>
              <h3 className="text-center">
                Review
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ marginLeft: "10px", color: "#28a745" }}
                />
              </h3>
              <p className="text-center">
                Your document has been processed and is ready for review. You
                can preview the redacted content in the PDF viewer below. If
                everything looks good, you can download the final redacted
                document.
              </p>
              <Alert key={0} variant={"light"}>
                <AlertHeading>
                  Disclaimer{" "}
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{ marginLeft: "10px", color: "#ff6700" }}
                  />
                </AlertHeading>
                <hr />
                The redacted document has been processed to the best of our
                ability. However, it is important to manually review the content
                before sharing or publishing. Sensitive information might still
                remain.<br></br> If you encounter any issues or have concerns
                regarding the redacted content, please contact us for further
                assistance.
              </Alert>
              <Modal show={modalIsOpen} onHide={closeModal} centered size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Document Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "80vh" }}>
                  {pdfBlob && (
                    <iframe
                      title="PDF Preview"
                      src={URL.createObjectURL(pdfBlob)}
                      style={{ width: "100%", height: "100%", border: "none" }}
                    />
                  )}
                </Modal.Body>
              </Modal>
              <div className="d-flex justify-content-center mt-4">
                <Button variant="primary" onClick={openModal} className="mx-2">
                  View Document Preview
                </Button>

                <Button
                  variant="success"
                  onClick={handleDownload}
                  className="mx-2"
                >
                  Download PDF
                </Button>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between mt-4">
            {step > 1 ? <Button onClick={prevStep}>Back</Button> : <div> </div>}
            {step < 3 && (
              <Button
                onClick={() => {
                  if (step === 2) {
                    redactDocument();
                  }
                  nextStep();
                }}
                type="submit"
              >
                {step === 2 ? "Secure My Document!" : "Next"}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FileUpload;
