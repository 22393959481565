import "../styles/header.css";
import Navigation from "./navigation";
import { Row } from "react-bootstrap";

function Header() {
  return (
    <div className="headerContent">
      <div className="header">
        <Row>
          <h1 className="headerTitle">Visual Data Guard</h1>
        </Row>
        <Row>
          <h3 className="headerSlogan">Redact. Secure. Protect.</h3>
        </Row>
        <Navigation></Navigation>
      </div>
    </div>
  );
}

export default Header;
