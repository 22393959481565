import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import bannerImage from "../images/bannerImage.jpeg";
import "../styles/footer.css";

function Footer() {
  return (
    <footer className="footer mt-5">
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <img src={bannerImage} alt="Footer Banner" className="img-fluid" />
          </Col>
          <Col md={6} className="text-center">
            <h5 style={{ color: "white" }}>Follow Us</h5>
            <div className="social-icons">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className="mx-2" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className="mx-2" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="mx-2" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
