import "../styles/navigation.css";
import { Container, Navbar, Nav, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";

function Navigation() {
  const location = useLocation();
  return (
    <Container fluid className="navbarContainer">
      <Row className="headerRow mb-3">
        <Navbar expand="lg" className="custom-navbar">
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="100"
              height="100"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-5">
              <Nav.Link
                as={Link}
                to="/"
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/app"
                className={`nav-link ${
                  location.pathname === "/app" ? "active" : ""
                }`}
              >
                App
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/aboutus"
                className={`nav-link ${
                  location.pathname === "/aboutus" ? "active" : ""
                }`}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className={`nav-link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
    </Container>
  );
}

export default Navigation;
