import "../styles/home.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faUser,
  faShieldAlt,
  faFilePdf,
  faRocket,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";

function Home() {
  return (
    <Row className="g-4">
      <Col md={4}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faFileAlt} size="3x" />
            <Card.Title className="mt-3 cardTitle">
              Automated Redaction
            </Card.Title>
            <Card.Text>
              Effortlessly redact sensitive information from documents with our
              automated tool, minimising human error and saving time.
            </Card.Text>
            <Button variant="success">Learn More</Button>
          </Card.Body>
        </Card>
      </Col>

      <Col md={4}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faUser} size="3x" />
            <Card.Title className="mt-3 cardTitle">
              User-Friendly Interface
            </Card.Title>
            <Card.Text>
              Our intuitive interface makes it easy for anyone to upload,
              select, and redact information without technical expertise.
            </Card.Text>
            <Button variant="success">Explore Features</Button>
          </Card.Body>
        </Card>
      </Col>

      <Col md={4}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faShieldAlt} size="3x" />
            <Card.Title className="mt-3 cardTitle">
              Data Security Compliance
            </Card.Title>
            <Card.Text>
              Stay compliant with regulations like GDPR while ensuring your
              sensitive data remains secure throughout the redaction process.
            </Card.Text>
            <Button variant="success">Find Out How</Button>
          </Card.Body>
        </Card>
      </Col>

      <Col md={4}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faFilePdf} size="3x" />
            <Card.Title className="mt-3 cardTitle">
              Versatile File Support
            </Card.Title>
            <Card.Text>
              Upload and process a variety of file formats, including PDFs and
              images, for comprehensive redaction capabilities.
            </Card.Text>
            <Button variant="success">See Supported Formats</Button>
          </Card.Body>
        </Card>
      </Col>

      <Col md={4}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faRocket} size="3x" />
            <Card.Title className="mt-3 cardTitle">
              Efficient Processing
            </Card.Title>
            <Card.Text>
              Experience fast and reliable document processing, even for large
              files, without compromising on accuracy.
            </Card.Text>
            <Button variant="success">Learn About Performance</Button>
          </Card.Body>
        </Card>
      </Col>

      <Col md={4}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faClipboardCheck} size="3x" />
            <Card.Title className="mt-3 cardTitle">
              Testing & Quality Assurance
            </Card.Title>
            <Card.Text>
              Our rigorous testing ensures that the redaction system performs
              accurately and efficiently across various document types.
            </Card.Text>
            <Button variant="success">Discover Our Process</Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Home;
